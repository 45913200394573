import { Theme, ComponentsProps, ComponentsOverrides } from "@mui/material/styles";
import { CSSProperties } from "@mui/material/styles/createMixins";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import {
  COLOR_BLACK,
  COLOR_BUTTON_ACTIVE,
  COLOR_BUTTON_HOVER,
  COLOR_FOCUSED,
  COLOR_GREYSCALE_BLACK_54,
  COLOR_GREYSCALE_BLACK_75,
  COLOR_PRIMARY_BLACK,
  COLOR_PRIMARY_WHITE,
} from "~/theme/colors";
import { FontWeights } from "~/theme/typography";

import { ARIAL_BLACK_FONT_FAMILY } from "../utils/font.constants";

export const getMuiButton = (theme: Theme): ComponentsOverrides["MuiButton"] => {
  const containedCommon: CSSProperties = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    color: COLOR_PRIMARY_WHITE,
    backgroundColor: COLOR_PRIMARY_BLACK,
    "&, &.MuiButton-sizeLarge": {
      ...theme.typography.button,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    borderWidth: 0,
    "&:hover": {
      backgroundColor: COLOR_GREYSCALE_BLACK_75,
    },
    "&:active": {
      backgroundColor: COLOR_PRIMARY_BLACK,
    },
    "&.MuiButton-disabled": {
      opacity: 0.25,
      color: COLOR_PRIMARY_WHITE,
      backgroundColor: COLOR_PRIMARY_BLACK,
    },
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1.5),
    },
    "& .MuiButton-endIcon": {
      marginLeft: theme.spacing(1.5),
    },
  };
  const outlinedCommon: CSSProperties = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    borderWidth: 0,
    borderStyle: "none",
    color: COLOR_PRIMARY_BLACK,
    borderColor: COLOR_PRIMARY_BLACK,
    backgroundColor: "transparent",
    "&, &.MuiButton-sizeLarge": {
      ...theme.typography.button,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:after": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      borderWidth: 2,
      borderStyle: "solid",
      borderColor: COLOR_BLACK,
      pointerEvents: "none",
    },
    "&:hover": {
      borderWidth: 0,
      borderColor: COLOR_PRIMARY_BLACK,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: COLOR_BUTTON_HOVER,
    },
    "&:active": {
      borderWidth: 0,
      borderColor: COLOR_PRIMARY_BLACK,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: COLOR_BUTTON_ACTIVE,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      borderWidth: 0,
      color: COLOR_PRIMARY_BLACK,
      borderColor: COLOR_PRIMARY_BLACK,
    },
  };
  const textCommon: CSSProperties = {
    fontFamily: ARIAL_BLACK_FONT_FAMILY,
    height: 40,
    color: COLOR_PRIMARY_BLACK,
    backgroundColor: "transparent",
    textTransform: "none",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    textDecoration: "none",

    ...theme.typography.body1,

    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(1),
    },
    "& .MuiButton-endIcon": {
      marginLeft: theme.spacing(1),
    },
    "&:hover": {
      backgroundColor: COLOR_BUTTON_HOVER,
    },
    "&:active": {
      backgroundColor: COLOR_BUTTON_ACTIVE,
    },
    "&.Mui-disabled": {
      opacity: 0.5,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: "transparent",
    },
    "&.MuiButton-sizeLarge": {
      height: 48,
    },
  };

  return {
    root: {
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      height: 48,
      minWidth: "auto",
      textTransform: "uppercase",
      borderRadius: 0,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      transition: "none",

      "&, &$sizeLarge": {
        ...theme.typography.button,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },

      "&.focusVisible:before": {
        content: "''",
        display: "block",
        position: "absolute",
        top: -6,
        bottom: -6,
        left: -6,
        right: -6,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: COLOR_FOCUSED,
      },
      "&.alignLeft $label": {
        justifyContent: "left",
      },
      "&.alignLeft $endIcon": {
        marginLeft: "auto",
      },

      "&.underlined": {
        textDecoration: "underline",
      },

      // links
      "&.body1": {
        ...theme.typography.body1,
      },
      "&.body2": {
        ...theme.typography.body2,
      },
      "&.body1, &.body2": {
        margin: 0,
        padding: 0,
        cursor: "pointer",
        color: COLOR_BLACK,
        backgroundColor: "transparent",
        height: 24,
        lineHeight: "24px",
        "& > strong": {
          fontWeight: FontWeights.fontWeightMedium,
        },
        "& $startIcon": {
          marginRight: theme.spacing(1),
        },
        "& $endIcon": {
          marginLeft: theme.spacing(1),
        },
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "transparent",
        },
        "&:disabled": {
          opacity: 1,
          color: COLOR_GREYSCALE_BLACK_54,
          backgroundColor: "transparent",
        },
        "&:focus": {
          backgroundColor: "transparent",
          "&:before": {
            top: -2,
            bottom: -2,
            left: -2,
            right: -2,
          },
        },
        "&.underlined": {
          textDecoration: "underline",
        },
      },
    },

    sizeLarge: {
      height: 56,
    },

    contained: {
      fontFamily: ARIAL_BLACK_FONT_FAMILY,
      color: COLOR_PRIMARY_BLACK,
      backgroundColor: "transparent",
      "&, &$sizeLarge": {
        ...theme.typography.button,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
      },
      "&:hover": {
        backgroundColor: COLOR_BUTTON_HOVER,
      },
      "&:active": {
        backgroundColor: COLOR_BUTTON_ACTIVE,
      },
      "&$disabled": {
        opacity: 0.5,
        color: COLOR_PRIMARY_BLACK,
        backgroundColor: "transparent",
      },
      "& $startIcon": {
        marginRight: theme.spacing(1),
      },
      "& $endIcon": {
        marginLeft: theme.spacing(1),
      },
    },
    containedPrimary: containedCommon,
    containedSecondary: containedCommon,

    outlined: outlinedCommon,
    outlinedPrimary: outlinedCommon,
    outlinedSecondary: outlinedCommon,

    text: textCommon,
    textPrimary: textCommon,
    textSecondary: textCommon,

    startIcon: {
      marginRight: theme.spacing(1.5),
      marginLeft: 0,
    },
    endIcon: {
      marginLeft: theme.spacing(1.5),
      marginRight: 0,
    },
  };
};

export const getMuiButtonDefaultProps = (): ComponentsProps["MuiButton"] => {
  return {
    disableElevation: true,
    disableFocusRipple: true,
    disableRipple: true,
    color: "primary",
    focusVisibleClassName: "focusVisible",
    ...getTestAutomationProps("button"),
  };
};
